import { BrandSearchResultModel } from "./brandSearchResultModel"
import { ContactSearchResultModel } from "./contactSearchResultModel"
import { DownloadSearchResultModel } from "./downloadSearchResultModel"
import { EventSearchResultModel } from "./eventSearchResultModel"
import { HistoryItemSearchResultModel } from "./historyItemSearchResultModel"
import { JobOfferSearchResultModel } from "./jobOfferSearchResultModel"
import { LocationSearchResultModel } from "./locationSearchResultModel"
import { MarketSearchResultModel } from "./marketSearchResultModel"
import { NavItemSearchResultModel } from "./navItemSearchResultModel"
import { PageSearchResultModel } from "./pageSearchResultModel"
import { ProductSearchResultModel } from "./productSearchResultModel"
import { UnknownSearchResultModel } from "./unknownSearchResultModel"

export class SearchResultFactory {
  static createSearchResult(searchResultLike) {
    switch (searchResultLike.type) {
      case "brand":
        return new BrandSearchResultModel(searchResultLike)
      case "market":
        return new MarketSearchResultModel(searchResultLike)
      case "nav-item":
        return new NavItemSearchResultModel(searchResultLike)
      case "contact":
        return new ContactSearchResultModel(searchResultLike)
      case "event":
        return new EventSearchResultModel(searchResultLike)
      case "page":
        return new PageSearchResultModel(searchResultLike)
      case "product":
        return new ProductSearchResultModel(searchResultLike)
      case "download":
        return new DownloadSearchResultModel(searchResultLike)
      case "history-item":
        return new HistoryItemSearchResultModel(searchResultLike)
      case "location":
        return new LocationSearchResultModel(searchResultLike)
      case "joboffer":
        return new JobOfferSearchResultModel(searchResultLike)
      default:
        return new UnknownSearchResultModel(searchResultLike)
    }
  }
}
