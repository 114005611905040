import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import ArrowLeftIcon from "../../images/icons/arrow_left.svg"
import ArrowRightIcon from "../../images/icons/arrow_right.svg"
import { HorizontalScrollContainerHelper } from "../../util/horizontal-scroll-container-helper"
import Tile from "../tile/tile"
import TileContentSpacer from "../tile/tile-content-spacer/tile-content-spacer"
import "./overview-slider.sass"

class OverviewSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      leftButtonActive: false,
      rightButtonActive: true,
    }
    this.sliderRef = React.createRef()
  }

  componentDidMount() {
    this.scrollContainerHelper = new HorizontalScrollContainerHelper(
      this.sliderRef.current,
      (state) => this.setState(state),
    )
  }

  componentWillUnmount() {
    this.scrollContainerHelper.destroy()
  }

  render() {
    const {
      children,
      title,
      linkPath,
      linkTextTranslatedString,
      size,
      noTile,
      bottomPadding,
    } = this.props
    const slideClass = classNames(
      "_fp-overview-slider__slide",
      { "_fp-overview-slider__slide--products": size === "products" },
      { "_fp-overview-slider__slide--medium": size === "medium" },
      { "_fp-overview-slider__slide--full": size === "full" },
    )

    let buttons = [
      {
        iconComponent: () => <ArrowLeftIcon />,
        active: this.state.leftButtonActive,
        onClick: () => this.scrollContainerHelper.scrollLeft(),
      },
      {
        iconComponent: () => <ArrowRightIcon />,
        active: this.state.rightButtonActive,
        onClick: () => this.scrollContainerHelper.scrollRight(),
      },
    ]

    // hide buttons if both are inactive
    if (!this.state.leftButtonActive && !this.state.rightButtonActive) {
      buttons = []
    }

    return (
      <Tile
        noTile={noTile}
        titleTranslatedString={title}
        linkPath={linkPath}
        linkTextTranslatedString={linkTextTranslatedString}
        iconButtons={buttons}
      >
        <div className={"_fp-overview-slider"} ref={this.sliderRef}>
          {React.Children.toArray(children).map((child, index) => {
            return (
              <div
                key={`fp-overview-slider-slide_${index}`}
                className={slideClass}
              >
                {child}
              </div>
            )
          })}
        </div>
        {bottomPadding && <TileContentSpacer />}
      </Tile>
    )
  }
}

OverviewSlider.propTypes = {
  noTile: PropTypes.bool,
  title: PropTypes.string,
  bottomPadding: PropTypes.bool,
  linkPath: PropTypes.string,
  linkTextTranslatedString: PropTypes.string,
  size: PropTypes.oneOf(["full", "products", "medium"]),
}

OverviewSlider.defaultProps = {
  noTile: true,
}

export default OverviewSlider
