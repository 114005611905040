import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import { useTranslation } from "../../../../i18n/translate"
import CartIcon from "../../../../images/icons/cart_full.svg"
import RemoveIcon from "../../../../images/icons/remove.svg"
import RemoveFromCartIcon from "../../../../images/icons/remove_from_cart.svg"
import { ProductModel } from "../../../../model/productModel"
import Button from "../../../button/button"

const CartButton = ({
  product,
  productsInCart,
  removeFromCart,
  addToCart,
  large,
  buttonStyle,
}) => {
  product = new ProductModel(product)
  const productIsInCart =
    productsInCart.findIndex((p) => {
      return p.id === product.id
    }) !== -1
  const { t } = useTranslation()

  let translationKey

  if (large) {
    if (productIsInCart) {
      translationKey = "PRODUCTS-SAMPLE_REQUEST-REMOVE_FROM_CART"
    } else {
      translationKey = "PRODUCTS-SAMPLE_REQUEST-ADD_TO_CART"
    }
  }

  let style = "green-soft"
  let iconComponent = <CartIcon />
  if (buttonStyle === "remove") {
    iconComponent = <RemoveIcon />
    if (productIsInCart) {
      style = "red"
    } else {
      style = "disabled"
    }
  } else if (buttonStyle === "default") {
    if (productIsInCart) {
      style = "green"
      iconComponent = <RemoveFromCartIcon />
    }
  }

  return (
    <Button
      buttonStyle={style}
      buttonLabel={t(translationKey)}
      buttonCallback={() =>
        productIsInCart ? removeFromCart(product.id) : addToCart(product)
      }
      buttonSvgIconComponent={iconComponent}
    />
  )
}

CartButton.propTypes = {
  buttonStyle: PropTypes.oneOf(["default", "remove"]),
  product: PropTypes.object,
  large: PropTypes.bool,
}

CartButton.defaultProps = {
  large: true,
  buttonStyle: "default",
}

const mapStateToProps = (state) => {
  return {
    productsInCart: state.cart,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (product) => dispatch({ type: "ADD_CART", product: product }),
    removeFromCart: (id) => dispatch({ type: "REMOVE_CART", id: id }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartButton)
