import { SearchResultModel } from "./searchResultModel"

export class HistoryItemSearchResultModel extends SearchResultModel {
  getName(locale) {
    if (!this.document || !this.document.year) return ""
    return this.document.year
  }

  getLinkUrl() {
    return "/munzing-group/history/"
  }
}
