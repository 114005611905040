import { stripHtml } from "string-strip-html"

import { SearchResultModel } from "./searchResultModel"

export class LocationSearchResultModel extends SearchResultModel {
  getName(locale) {
    return this.document.name ? this.document.name[locale] : ""
  }

  getText(locale) {
    if (!this.document || !this.document.description) return ""
    return stripHtml(this.document.description[locale]).result
  }

  getLinkUrl() {
    return "/munzing-group/munzing-worldwide/" + this.document.url || "#"
  }
}
