import { SearchResultModel } from "./searchResultModel"

export class EventSearchResultModel extends SearchResultModel {
  getName(locale) {
    return this.document.name ? this.document.name[locale] : ""
  }

  getLinkUrl() {
    return "/munzing-group/news-and-events/" + (this.document.url || "")
  }
}
