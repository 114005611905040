import { graphql, useStaticQuery } from "gatsby"

import { HistoryItemModel } from "../model/historyItemModel"

const useHistoryItems = () => {
  return useStaticQuery(graphql`
    query HistoryItemsQuery {
      allStrapiHistoryItems {
        edges {
          node {
            id
            strapiId
            year
            text {
              key
            }
            title {
              key
            }
            year_label {
              key
            }
            image {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 960, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allStrapiHistoryItems.edges.map((historyItemNode) => {
    return new HistoryItemModel(historyItemNode.node)
  })
}

export default useHistoryItems
