import { stripHtml } from "string-strip-html"

import { SearchResultModel } from "./searchResultModel"

export class JobOfferSearchResultModel extends SearchResultModel {
  getName(locale) {
    return this.document.positionName
      ? this.document.positionName[locale] ?? ""
      : ""
  }

  getText(locale) {
    if (
      !this.document ||
      !this.document.description ||
      !this.document.description[locale]
    )
      return ""
    return stripHtml(this.document.description[locale] || "").result
  }

  getLinkUrl() {
    return this.document.url
  }

  shouldBeHidden(locale) {
    return !this.getText(locale)
  }
}
