export class HistoryItemModel {
  constructor(e) {
    this.id = e.strapiId || e.id
    this.year_label = e.year_label || {}
    this.year = e.year
    this.text = e.text || {}
    this.title = e.title || {}
    if (e.image && e.image.image) {
      this.image = e.image
    }
  }
}
