import axios from "axios"
import { unflatten } from "flat"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import handleViewport from "react-in-viewport"

import OverviewSlider from "../components/overview-slider/overview-slider"
import PageHeader from "../components/page-header/page-header"
import SearchBar from "../components/search-bar/search-bar"
import SearchResult from "../components/search-result/search-result"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import useHistoryItems from "../hooks/useHistoryItems"
import { useTranslation } from "../i18n/translate"
import { SearchResultFactory } from "../model/search-result/searchResultFactory"
import { makePromiseCancelable } from "../util/make-promise-cancelable"
import "./search.sass"

const Loader = (props) => {
  const { forwardedRef } = props
  return <div ref={forwardedRef}></div>
}

const ViewportBlock = handleViewport(Loader)

const searchApi = axios.create({
  // eslint-disable-next-line
  baseURL: SEARCH_API_URL,
  method: "POST",
  headers: { "content-type": "application/json" },
})

let lastQuery = ""
let lastRequest

const SearchPage = ({ location }) => {
  const { i18n, t } = useTranslation()

  const history = useHistoryItems()

  let initialQuery = ""

  if (typeof window !== "undefined" && window.history.replaceState) {
    let searchParams = new URLSearchParams(window.location.search)

    if (searchParams.has("q")) {
      initialQuery = searchParams.get("q")
    }
  }

  const [total, setTotal] = useState(0)
  const [results, setResults] = useState([])
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState(initialQuery)
  const [loading, setLoading] = useState(false)

  if (typeof window !== "undefined" && window.history.replaceState) {
    let searchParams = new URLSearchParams(window.location.search)

    if (query !== searchParams.get("q")) {
      if (query) {
        searchParams.set("q", query)
      } else {
        searchParams.delete("q")
      }
      let newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        (query ? "?" : "") +
        searchParams.toString()
      window.history.replaceState({ path: newUrl }, "", newUrl)
    }
  }

  const processSearchResult = (res, additionalResults = []) => {
    const unflattenedSearchResults = [
      ...additionalResults,
      ...(res.data.results || []).map((r) => unflatten(r)),
    ]

    const firstHistoryItemIndex = unflattenedSearchResults.findIndex(
      (el) => el.type === "history-item",
    )

    const filters = [
      (el) => el.type !== "history-item",
      (el) => {
        const lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 1)
        return !(el.type === "event" && new Date(el.document.date) < lastMonth)
      },
    ]

    let search = unflattenedSearchResults.filter((el) => {
      for (const filter of filters) {
        if (!filter(el)) {
          return false
        }
      }
      return true
    })

    if (firstHistoryItemIndex >= 0) {
      search.splice(firstHistoryItemIndex, 0, {
        type: "history-item",
        document: history,
      })
    }

    setResults(search)
    setTotal(res.data.total)
  }

  if (query !== lastQuery) {
    lastQuery = query

    if (lastRequest) {
      lastRequest.cancel()
    }

    lastRequest = makePromiseCancelable(
      searchApi.post("/", JSON.stringify({ query, page: 0, limit: 20 })),
    )

    lastRequest.then((res) => processSearchResult(res)).catch(() => undefined)
  }

  const loadMore = () => {
    setLoading(true)
    searchApi
      .post("/", JSON.stringify({ query, page: page + 1, limit: 20 }))
      .then((res) => {
        processSearchResult(res, results)
        setPage(res.data.page)
        setLoading(false)
      })
  }

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("SEARCH-META_TITLE")}
        descriptionTranslatedString={t("SEARCH-META_DESCRIPTION")}
      />
      <PageHeader
        container={"tertiary"}
        levelTwoTitle={t("SEARCH-META_TITLE")}
        levelThreeTitle={t("SEARCH-META_TITLE")}
      />
      <div className="_fp-global-container-tertiary">
        <div className="_fp-search">
          <SearchBar query={query} setQuery={setQuery} />
          {query && (
            <p className={"_fp-search__count"}>
              {t("SEARCH-RESULTS_COUNT", {
                postProcess: "interval",
                count: total,
              })}
            </p>
          )}

          <ul className={"_fp-search__results"}>
            {results
              .map((result) => SearchResultFactory.createSearchResult(result))
              .filter((result) => !result.shouldBeHidden(i18n.language))
              .map((result, i) => {
                if (result.type === "history-item") {
                  return (
                    <li
                      style={{ margin: "2rem 0" }}
                      className="_fp-search__results__result"
                      key={i}
                    >
                      <OverviewSlider
                        linkTextTranslatedString={t("GLOBAL-OUR_HISTORY-TITLE")}
                        size={"medium"}
                        linkPath={"/munzing-group/history/"}
                        title={t("GLOBAL-OUR_HISTORY-TITLE")}
                      >
                        {result.document.map((item) => {
                          return (
                            <Tile
                              tileSize={"s"}
                              titleTranslatedString={t(item.year_label.key)}
                            >
                              <div
                                className={"_fp-history-search-result__item"}
                              >
                                <div
                                  className={
                                    "_fp-history-search-result__item__image"
                                  }
                                >
                                  <GatsbyImage
                                    alt={t(item.title.key)}
                                    image={
                                      item.image.image.localFile.childImageSharp
                                        .gatsbyImageData
                                    }
                                    style={{ height: "100%", width: "100%" }}
                                  />
                                </div>
                                <p
                                  className={
                                    "_fp-heading-5 _fp-history-search-result__item__title"
                                  }
                                >
                                  {t(item.title.key)}
                                </p>
                                <p className={"_fp-text"}>{t(item.text.key)}</p>
                              </div>
                            </Tile>
                          )
                        })}
                      </OverviewSlider>
                    </li>
                  )
                }

                return (
                  <li className={"_fp-search__results__result"} key={i}>
                    <SearchResult
                      result={result}
                      locale={i18n.language}
                      t={t}
                    />
                  </li>
                )
              })}
          </ul>
          {total > results.length && (
            <ViewportBlock onEnterViewport={() => loadMore()}></ViewportBlock>
          )}
          {loading && (
            <div className={"_fp-search__loading-more"}>
              {t("SEARCH-LOADING_MORE...")}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SearchPage
