import { SearchResultModel } from "./searchResultModel"

export class ContactSearchResultModel extends SearchResultModel {
  getName() {
    return this.document.name || ""
  }

  getText(locale) {
    if (!this.document || !this.document.address) return ""
    return this.document.address
  }

  getLinkUrl() {
    return "/contact"
  }
}
