export const makePromiseCancelable = (promise) => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
    )
  })

  return {
    then(fn1, fn2) {
      return wrappedPromise.then(fn1, fn2)
    },
    catch(fn1) {
      return wrappedPromise.catch(fn1)
    },
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    },
  }
}
