import { SearchResultModel } from "./searchResultModel"

export class DownloadSearchResultModel extends SearchResultModel {
  localFile

  getName(locale) {
    if (!this.document || !this.document.name[locale]) return ""
    return this.document.name[locale]
  }

  constructor(res) {
    super(res)

    this.id = res.document.id
    this.localFile = res.document.localFile
  }
}
