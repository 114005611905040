export class SearchResultModel {
  id
  type
  document
  highlights

  constructor(s) {
    this.id = s.id
    this.type = s.type || s._class || "unknown"
    this.document = s.document
    this.highlights = s.highlights
  }

  getLinkUrl() {
    return "#"
  }

  getText(locale) {
    if (!this.document || !this.document.text) return ""
    return this.document.text[locale]
  }

  getName(locale) {
    if (!this.document || !this.document.title) return ""
    return this.document.title[locale]
  }

  shouldBeHidden(locale) {
    return false
  }
}
