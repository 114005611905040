import { observeDOM } from "./observe-dom"

export class HorizontalScrollContainerHelper {
  constructor(scrollContainer, onUpdate, fixedScrollWidth) {
    if (fixedScrollWidth) {
      this.fixedScrollWidth = fixedScrollWidth
    }
    this.scrollContainer = scrollContainer
    this.onUpdate = onUpdate
    this.state = {
      leftButtonActive: false,
      rightButtonActive: true,
    }

    this.scrollListener = (ev) => this._onScroll(ev)
    this.scrollContainer.addEventListener("scroll", this.scrollListener, {
      passive: true,
    })
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => this._onScroll(), {
        passive: true,
      })
    }
    this.domObserver = observeDOM()(scrollContainer, () => this._onScroll())
    this._onScroll()
  }

  _getColumnWidth() {
    if (this.fixedScrollWidth) {
      return this.fixedScrollWidth
    }
    return this.scrollContainer.children[0]?.clientWidth || 0
  }

  _scrollBy(px) {
    this.scrollContainer.scrollBy({ left: px, behavior: "smooth" })
  }

  scrollLeft() {
    // let scrollBy;
    //
    const columnWidth = this._getColumnWidth()
    // const scrollLeft = this.scrollContainer.scrollLeft;
    //
    // /// Handle Case where previous product is currently in the view (not productColumn with away from current scroll position)
    // if (scrollLeft % columnWidth > 0) {
    //     scrollBy = Math.max(scrollLeft % columnWidth, columnWidth)
    //     /// Scroll by productColumn everywhere else
    // } else {
    //     scrollBy = columnWidth
    // }

    this._scrollBy(-columnWidth)
  }

  scrollRight() {
    this._scrollBy(this._getColumnWidth())
  }

  destroy() {
    if (this.scrollListener) {
      this.scrollContainer.removeEventListener("scroll", this.scrollListener)
    }
    this.domObserver.disconnect()
  }

  _onScroll() {
    const newPos = this.scrollContainer.scrollLeft
    const maxWidth =
      this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth

    const leftActive = newPos !== 0
    const rightActive = newPos < maxWidth

    if (
      this.state.leftButtonActive !== leftActive ||
      this.state.rightButtonActive !== rightActive
    ) {
      this.state.leftButtonActive = leftActive
      this.state.rightButtonActive = rightActive

      this._notify()
    }
  }

  _notify() {
    // notify listeners
    if (typeof this.onUpdate === "function") {
      this.onUpdate(this.state)
    }
  }
}
