import { SearchResultModel } from "./searchResultModel"

export class PageSearchResultModel extends SearchResultModel {
  getName(locale) {
    return this.document.title ? this.document.title[locale] : ""
  }

  getText(locale) {
    if (!this.document || !this.document.pageTextBlocks) return ""
    return this.document.pageTextBlocks[0][locale]
  }

  getLinkUrl() {
    return this.document.url || "#"
  }
}
