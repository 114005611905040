import { SearchResultModel } from "./searchResultModel"

export class BrandSearchResultModel extends SearchResultModel {
  constructor(res) {
    super(res)
    this.url = this.document.url
  }

  getName(locale) {
    if (!this.document || !this.document.name) return ""
    return this.document.name[locale]
  }

  getText(locale) {
    if (!this.document || !this.document.description) return ""
    return this.document.description[locale]
  }

  getLinkUrl() {
    return `/brands/${this.url}`
  }
}
