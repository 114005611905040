import PropTypes from "prop-types"
import React from "react"

import { withTranslation } from "../../i18n/translate"
import SearchIcon from "../../images/icons/search.svg"
import "./search-bar.sass"

class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.searchInputRef = React.createRef()
  }

  handleSearch = (event) => {
    this.props.setQuery(event.target.value || "")
  }

  componentDidMount() {
    if (this.searchInputRef) {
      this.searchInputRef.current.focus()
    }
  }

  render() {
    const { t, query = "" } = this.props

    return (
      <div className={"_fp-search-bar"}>
        <div className="_fp-search-bar__icon">
          <SearchIcon />
        </div>
        <input
          className={"_fp-search-bar__input"}
          type="text"
          placeholder={t("SEARCH-SEARCH_FIELD_PLACEHOLDER")}
          onChange={this.handleSearch}
          value={query}
          ref={this.searchInputRef}
        />
      </div>
    )
  }
}

SearchBar.propTypes = {
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default withTranslation()(SearchBar)
