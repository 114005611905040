import urlify from "../../util/urlify"
import { SearchResultModel } from "./searchResultModel"

export class ProductSearchResultModel extends SearchResultModel {
  description

  constructor(searchResultLike) {
    super(searchResultLike)

    this.description = searchResultLike.document.description
    this.document.id = +this.document.id
    this.id = this.document.id
  }

  getName() {
    return this.document.name || ""
  }

  getText(locale) {
    return this.getDescription(locale)
  }

  getDescription(locale) {
    return this.description[locale] || this.description["en"] || ""
  }

  getLinkUrl() {
    return `/products/${urlify(this.getName("en"))}`
  }

  getNameWithHighlight(locale) {
    const highlight = this.highlights.find((h) => h.field === "name")

    if (!highlight) {
      return this.getName(locale)
    }

    return highlight.occurrences[0]
  }

  getDescriptionWithHighlight(locale) {
    const highlight = this.highlights.find(
      (h) => h.field === "description." + locale,
    )

    if (!highlight) {
      return this.getDescription(locale)
    }

    console.log(highlight)

    return highlight.occurrences[0]
  }
}
