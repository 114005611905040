import { SearchResultModel } from "./searchResultModel"

export class MarketSearchResultModel extends SearchResultModel {
  getName(locale) {
    return this.document.name ? this.document.name[locale] : ""
  }

  getText(locale) {
    if (!this.document || !this.document.description) return ""
    return this.document.description[locale]
  }

  getLinkUrl() {
    return "/markets/" + this.document.url || "#"
  }
}
