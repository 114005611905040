import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import { useTranslation } from "../../../../i18n/translate"
import ComparisonIcon from "../../../../images/icons/comparison.svg"
import RemoveFromComparisonIcon from "../../../../images/icons/remove_from_comparison.svg"
import { ProductModel } from "../../../../model/productModel"
import Button from "../../../button/button"

const ComparisonButton = ({
  product,
  productsInComparison,
  removeFromComparison,
  addToComparison,
  large,
}) => {
  product = new ProductModel(product)
  const productIsInComparison =
    productsInComparison.findIndex((p) => {
      return p.id === product.id
    }) !== -1
  const { t } = useTranslation()

  let translationKey

  if (large) {
    if (productIsInComparison) {
      translationKey = "PRODUCTS-COMPARE_PRODUCTS-REMOVE_FROM_COMPARISON"
    } else {
      translationKey = "PRODUCTS-COMPARE_PRODUCTS-ADD_TO_COMPARISON"
    }
  }

  return (
    <>
      {product.show_in_productlist && (
        <Button
          buttonStyle={productIsInComparison ? "green" : "green-soft"}
          buttonLabel={t(translationKey)}
          buttonCallback={() =>
            productIsInComparison
              ? removeFromComparison(product.id)
              : addToComparison(product)
          }
          buttonSvgIconComponent={
            productIsInComparison ? (
              <RemoveFromComparisonIcon />
            ) : (
              <ComparisonIcon />
            )
          }
        />
      )}
    </>
  )
}

ComparisonButton.propTypes = {
  product: PropTypes.object,
  large: PropTypes.bool,
}

ComparisonButton.defaultProps = {
  large: true,
}

const mapStateToProps = (state) => {
  return {
    productsInComparison: state.comparison,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToComparison: (product) =>
      dispatch({ type: "ADD_COMPARISON", product: product }),
    removeFromComparison: (id) =>
      dispatch({ type: "REMOVE_COMPARISON", id: id }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonButton)
