import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./badge.sass"

class Badge extends React.Component {
  constructor(props) {
    super(props)
    this.badgeWrapperRef = React.createRef()
    this.badgeContentRef = React.createRef()
    this.state = { fullWidth: false }

    // Exceptions
    if (props.textTranslationKey) {
      throw new Error(
        '"textTranslationKey" prop is deprecated, use textTranslatedString instead.',
      )
    }
    //
  }

  componentDidMount() {
    this.setState({ fullWidth: this.getFullWidth() })

    this.resizeListener = () => {
      this.setState({ fullWidth: this.getFullWidth() })
    }
    window.addEventListener("resize", this.resizeListener)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener)
  }

  /// Returns true is badge size should be full-width
  getFullWidth() {
    if (this.props.style !== "default") {
      return false
    }
    if (
      !this.badgeContentRef ||
      !this.badgeWrapperRef ||
      !this.badgeContentRef.current ||
      !this.badgeWrapperRef.current
    ) {
      return false
    }

    return (
      this.badgeWrapperRef.current.clientWidth <
      this.badgeContentRef.current.clientWidth * this.props.badgeWidthRatio
    )
  }

  render() {
    return (
      <div
        ref={this.badgeWrapperRef}
        className={classNames(
          "_fp-badge-wrapper",
          {
            "_fp-badge-wrapper--full-width":
              this.props.style === "full-width" || this.state.fullWidth,
          },
          { "_fp-badge-wrapper--hidden": !this.props.showBadge },
          {
            "_fp-badge-wrapper--small":
              this.props.style === "small" || !this.state.fullWidth,
          },
        )}
      >
        <div className="_fp-badge-wrapper__badge">
          <div className="_fp-badge-wrapper__badge__content">
            {this.props.badgeSvgIconComponent && (
              <div className="_fp-badge-wrapper__badge__content__icon">
                {this.props.badgeSvgIconComponent}
              </div>
            )}
            <p
              ref={this.badgeContentRef}
              className="_fp-badge-wrapper__badge__content__text _fp-heading-6"
            >
              {this.props.textTranslatedString}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

Badge.propTypes = {
  textTranslatedString: PropTypes.string.isRequired,
  style: PropTypes.oneOf(["full-width", "default", "small"]),
  badgeWidthRatio: PropTypes.number,
  badgeSvgIconComponent: PropTypes.node,
  showBadge: PropTypes.bool,
}

Badge.defaultProps = {
  style: "default",
  badgeWidthRatio: 3,
  showBadge: true,
}

export default Badge
