import classNames from "classnames"
import { Link } from "gatsby"
import * as moment from "moment"
import * as prettyBytes from "pretty-bytes"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../i18n/localized-link"
import { useTranslation } from "../../i18n/translate"
import PDFIcon from "../../images/file-icons/pdf.svg"
import { SearchResultModel } from "../../model/search-result/searchResultModel"
import Badge from "../badge/badge"
import CartButton from "../product/actions/cart-button/cart-button"
import ComparisonButton from "../product/actions/comparison-button/comparison-button"
import Tile from "../tile/tile"
import TileContentSpacer from "../tile/tile-content-spacer/tile-content-spacer"
import "./search-result.sass"

const Highlight = ({ result, locale }) => {
  console.log(result)
  if (result.highlights && result.highlights.length > 0) {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: result.highlights[0].occurrences[0],
        }}
      />
    )
  } else {
    return (
      <p className={"_fp-search-result__highlight__text"}>
        {result.getText(locale)}
      </p>
    )
  }
}

const BrandSearchResult = ({ result, locale }) => {
  const { t } = useTranslation()

  return (
    <Tile
      tileSize={"s"}
      linkPath={result.getLinkUrl()}
      linkTextTranslatedString={t("BRANDS-MORE_ABOUT_THIS_BRAND")}
      className={"_fp-brand-search-result"}
    >
      <div className={"_fp-search-result__header"}>
        <LocalizedLink to={result.getLinkUrl()}>
          <h3 className={"_fp-search-result__header__title _fp-heading-3"}>
            {result.getName(locale)}
          </h3>
        </LocalizedLink>
        <div className="_fp-text _fp-text--small">{t("BRANDS-BRAND")}</div>
      </div>
      <div className="_fp-search-result__text">
        <p>{result.getText(locale)}</p>
      </div>
      <TileContentSpacer size={"extra-small"}></TileContentSpacer>
    </Tile>
  )
}

const MarketSearchResult = ({ result, locale }) => {
  const { t } = useTranslation()

  return (
    <Tile
      tileSize={"s"}
      linkPath={result.getLinkUrl()}
      linkTextTranslatedString={t("MARKETS-MORE_ABOUT_THIS_MARKET")}
      className={"_fp-brand-search-result"}
    >
      <div className={"_fp-search-result__header"}>
        <LocalizedLink to={result.getLinkUrl()}>
          <h3 className={"_fp-search-result__header__title _fp-heading-3"}>
            {result.getName(locale)}
          </h3>
        </LocalizedLink>
        <div className="_fp-text _fp-text--small">{t("MARKETS-MARKET")}</div>
      </div>
      <div className="_fp-search-result__text">
        <p>{result.getText(locale)}</p>
      </div>
      <TileContentSpacer size={"extra-small"}></TileContentSpacer>
    </Tile>
  )
}

const ProductSearchResult = ({ result, locale }) => {
  const product = result.document

  const { t } = useTranslation()

  return (
    <Tile
      tileSize={"s"}
      linkPath={result.getLinkUrl()}
      linkTextTranslatedString={t("PRODUCTS-MORE_ABOUT_THIS_PRODUCT")}
      className={"_fp-product-search-result"}
    >
      <div className={"_fp-search-result__header"}>
        <LocalizedLink to={result.getLinkUrl()}>
          <h3 className={"_fp-search-result__header__title _fp-heading-3"}>
            {result.getName(locale)}
          </h3>
        </LocalizedLink>
      </div>
      <div className="_fp-search-result__text">
        <p>{result.getText(locale)}</p>
      </div>
      <TileContentSpacer size={"extra-small"}></TileContentSpacer>
      <div className="_fp-product-search-result__actions">
        <ComparisonButton product={product}></ComparisonButton>
        <CartButton product={product}></CartButton>
      </div>
      <TileContentSpacer size={"small"}></TileContentSpacer>
    </Tile>
  )
}
const NewsEventsItemSearchResult = ({ result, locale }) => {
  const { t, i18n } = useTranslation()
  const event = result.document
  const imageResources = event.imageResources || []

  return (
    <Tile
      tileSize={"s"}
      linkPath={"/munzing-group/news-and-events/"}
      linkTextTranslatedString={t("GLOBAL-READ_MORE")}
      className={"_fp-unknown-search-result"}
    >
      <div
        className={classNames(
          "_fp-news-events-search-result",
          imageResources.length > 0
            ? ""
            : "_fp-news-events-search-result--no-image",
        )}
      >
        {imageResources.length > 0 && (
          <div className={"_fp-news-events-search-result__image"}>
            <img src={imageResources[0].publicURL} />
          </div>
        )}
        <div>
          <div
            className={
              "_fp-search-result__header _fp-news-events-search-result__header"
            }
          >
            <Link to={result.getLinkUrl()}>
              <h3 className={"_fp-search-result__header__title _fp-heading-3"}>
                {result.getName(locale)}
              </h3>
            </Link>
            <div className="_fp-text _fp-text--small">
              {t("MUNZING_GROUP-NEWS_AND_EVENTS-NEWS_AND_EVENTS")}
              {event.date && (
                <>
                  {" "}
                  —{" "}
                  <time dateTime={event.date}>
                    {moment(event.date).locale(i18n.language).format("LL")}
                  </time>
                </>
              )}
            </div>
          </div>
          <div className="_fp-search-result__text">
            <p className={"_fp-text"}>{result.getText(locale)}</p>
          </div>
        </div>
        <TileContentSpacer size={"extra-small"}></TileContentSpacer>
      </div>
    </Tile>
  )
}

const UnknownSearchResult = ({ result, locale }) => {
  return (
    <Tile tileSize={"s"} className={"_fp-unknown-search-result"}>
      <div className={"_fp-search-result__header"}>
        <LocalizedLink to={result.getLinkUrl()}>
          <h3 className={"_fp-search-result__header__title _fp-heading-3"}>
            {result.getName(locale)}
          </h3>
        </LocalizedLink>
        <Badge showBadge={true} textTranslatedString={result.type} />
      </div>
      {result.getText(locale) && (
        <div className="_fp-search-result__text">
          <p>{result.getText(locale)}</p>
        </div>
      )}
    </Tile>
  )
}

const LocationSearchResult = ({ result, locale }) => {
  const { t } = useTranslation()
  return (
    <Tile
      linkPath={result.getLinkUrl()}
      linkTextTranslatedString={t(
        "MUNZING_GROUP-MUNZING_WORLDWIDE-LOCATIONS-MORE_ABOUT_THIS_LOCATION",
      )}
      tileSize={"s"}
      className={"_fp-location-search-result"}
    >
      <div className={"_fp-search-result__header"}>
        <LocalizedLink to={result.getLinkUrl()}>
          <h3 className={"_fp-search-result__header__title _fp-heading-3"}>
            {result.getName(locale)}
          </h3>
        </LocalizedLink>
        <div className="_fp-text _fp-text--small">
          {t("MUNZING_GROUP-MUNZING_WORLDWIDE-MUNZING_WORLDWIDE")}
        </div>
      </div>
      {result.getText(locale) && (
        <div className="_fp-search-result__text">
          <p>{result.getText(locale)}</p>
        </div>
      )}
      <TileContentSpacer size={"extra-small"}></TileContentSpacer>
    </Tile>
  )
}

const JobOfferSearchResult = ({ result, locale }) => {
  const { t } = useTranslation()
  return (
    <Tile
      linkPath={result.getLinkUrl()}
      linkTextTranslatedString={t("GLOBAL-LEARN_MORE")}
      tileSize={"s"}
      className={"_fp-location-search-result"}
    >
      <div className={"_fp-search-result__header"}>
        <LocalizedLink to={result.getLinkUrl()}>
          <h3 className={"_fp-search-result__header__title _fp-heading-3"}>
            {result.getName(locale)}
          </h3>
        </LocalizedLink>
      </div>
      {result.getText(locale) && (
        <div className="_fp-search-result__text">
          <p>{result.getText(locale)}</p>
        </div>
      )}
      <TileContentSpacer size={"extra-small"}></TileContentSpacer>
    </Tile>
  )
}

const PageSearchResult = ({ result, locale }) => {
  const { t } = useTranslation()
  return (
    <Tile
      linkTextTranslatedString={t("GLOBAL-READ_MORE")}
      linkPath={result.getLinkUrl()}
      tileSize={"s"}
      className={"_fp-page-search-result"}
    >
      <div className={"_fp-search-result__header"}>
        <LocalizedLink to={result.getLinkUrl()}>
          <h3 className={"_fp-search-result__header__title _fp-heading-3"}>
            {result.getName(locale)}
          </h3>
        </LocalizedLink>
      </div>
      <div className="_fp-search-result__text">
        <p>{result.getText(locale)}</p>
      </div>
      <TileContentSpacer size={"extra-small"}></TileContentSpacer>
    </Tile>
  )
}

const DownloadSearchResult = ({ result, locale }) => {
  const { t, i18n } = useTranslation()

  const fileSize = result.localFile.size

  return (
    <Tile tileSize={"s"}>
      <div className="_fp-download-search-result">
        <div className="_fp-download-search-result__icon">
          <PDFIcon />
        </div>
        <div>
          <div>
            <a href={result.localFile.publicURL} target={"_blank"}>
              <h3 className={"_fp-search-result__header__title _fp-heading-4"}>
                {result.getName(locale)} (
                {t("GLOBAL-LANGUAGE-" + result.document.language)})
              </h3>
            </a>
            <p className={"_fp-text _fp-text--small"}>
              PDF —{" "}
              {fileSize ? (
                prettyBytes(fileSize, { locale: i18n.language })
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
      </div>
    </Tile>
  )
}

const SearchResult = (props) => {
  const { result } = props

  switch (result.type) {
    case "brand":
      return <BrandSearchResult {...props} />
    case "market":
      return <MarketSearchResult {...props} />
    case "product":
      return <ProductSearchResult {...props} />
    case "event":
      return <NewsEventsItemSearchResult {...props} />
    case "download":
      return <DownloadSearchResult {...props} />
    case "page":
      return <PageSearchResult {...props} />
    case "location":
      return <LocationSearchResult {...props} />
    case "joboffer":
      return <JobOfferSearchResult {...props} />
    case "nav-item":
      return <></>
    default:
      return <></>
    // return <UnknownSearchResult {...props}/>
  }
}

SearchResult.propTypes = {
  result: PropTypes.instanceOf(SearchResultModel),
  brands: PropTypes.array,
  locale: PropTypes.string,
}

export default SearchResult
